.dashboard-container {
  padding: 20px 20px 0px 20px;
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}
/*
#index-0 {
  background-color: lightblue;
}

#index-1 {
  background-color: orange;
}

#index-2 {
  background-color: rgb(157, 157, 224);
}

#index-3 {
  background-color: violet;
}

#index-4 {
  background-color: rgb(161, 212, 161);
} */

.dashboard-card {
  margin: 10px;
  width: 200px;
  min-height: 100px;
  transition: width 0s, height 0s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  border: 1px solid #525525 !important;
  border-radius: 4px;
  padding: 5px;
}

.dashboard-card:hover {
  cursor: pointer;
  width: 198px;
  min-height: 98px;
  box-shadow: 0.5px 0.5px 10px #dbc1c1;
}

.dashboard-card-img {
  width: 80%;
  margin-left: 15px;
  height: 90px;
}

.dashboard-card-title {
  width: 80%;
  padding: 10px 5px 0px 2px;
  color: black !important;
  font-weight: 1000 !important;
  font-size: 16px !important;
}

.dashboard-card-description {
  padding: 2px;
  height: 125px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: visible;
  scrollbar-width: thin !important;
  margin-top: 2px;
  font-size: 13px;
  text-align: justify;
}

.dashboard-card-description::-webkit-scrollbar {
  width: 6px;
}

.dashboard-card-description::-webkit-scrollbar-button {
  display: none;
}

.dashboard-card-description::-webkit-scrollbar-track {
  border-radius: 8px;
  background: #f1f1f1;
}

.dashboard-card-description::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.dashboard-card-description::-webkit-scrollbar-thumb:hover {
  background: #555;
}
