.navbar {
  width: 100% !important;
}

.greeting {
  margin-right: 20px;
  font-weight: 800 !important;
  font-size: 16px !important;
  text-align: start;
  width: 325px;
}

.greeting-secondary {
  font-size: 15px !important;
  margin-right: 20px;
  font-weight: 300 !important;
}

.abyss-page-header-top-nav-menu {
  font-size: 13px !important;
  width: max-content !important;
}

@media screen and (min-width: 1950px) {
  .abyss-page-header-top-nav-menu {
    font-size: 18px !important;
  }
}

.abyss-c-kjMckx {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.nav-button {
  color: black !important;
}

/* .abyss-link-root {
  text-decoration: none !important;
  border-radius: 4px;
}

.abyss-link-active {
  margin-left: 10px !important;
  padding-left: 5px !important;
  padding-right: 5px;
  background: #dacae4;
  margin: 2px;
} */

.secondary-menu {
  color: #002677 !important;
  background-color: white !important;
  padding: 8px;
}

.secondary-menu:hover {
  background-color: #002677 !important;
  color: white !important;
}

.remove-link-underline {
  /* text-decoration: none !important; */
  align-items: center;
  height: 30px;
  display: flex;
  font-size: medium !important;
  color: #002677 !important;
  font-weight: 600 !important;
}

.secondary-menu:hover .remove-link-underline {
  align-items: center;
  height: 30px;
  display: flex;
  font-size: medium !important;
  background-color: #002677;
  color: white !important;
  font-weight: 600 !important;
}

.abyss-link-icon {
  fill: #002677 !important;
  width: 15px !important;
  height: 15px !important;
}

.secondary-menu:hover .abyss-link-icon {
  fill: white !important;
  width: 15px !important;
  height: 15px !important;
}
/*
.abyss-icon-material {
  width: 15px !important;
  height: 15px !important;
  fill: #002677 !important;
}

.abyss-icon-material:hover {
  fill: #002677 !important;
} */
