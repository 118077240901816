.abyss-c-hKUTpG:hover {
  background-color: #002677 !important;
  color: white;
}

.abyss-c-hKUTpG:active {
  background-color: #002677 !important;
  color: white;
}

.abyss-c-hKUTpG[data-state='open'] {
  background-color: #002677 !important;
  color: white;
}

.submenu-container {
  width: 100%;
  background-color: #002677;
  position: fixed;
}

.subMenu {
}

.submenu-content {
  display: flex;
  min-height: 10px;
  width: 90%;
  margin: auto;
}

.submenu-title {
  font-size: 14px !important;
  margin: 10px;
  color: white;
  font-weight: 600;
}

.submenu-options {
  font-size: 14px;
  margin: 12px 10px 10px 10px;
  color: white;
}
