.parent {
  margin: 50px;
}

.des-container {
  display: flex;
  margin: auto;
  width: 80%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 20px;
}

.team-logo {
  width: 50%;
}

.team-image {
  width: 100%;
  height: 100px;
  object-fit: fill;
  margin: 10px;
  padding: 20px;
}

.team-details {
  margin: 10px;
  padding: 20px;
}

.team-title {
  font-size: large;
  font-weight: 600;
}

.team-description {
  text-align: justify;
}
