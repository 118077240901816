.frame-wrapper {
  width: 90%;
  margin: auto;
}

.frame-wrapper::-webkit-scrollbar {
  width: 6px !important;
}

.frame-wrapper::-webkit-scrollbar-button {
  display: none;
}

.frame-wrapper::-webkit-scrollbar-track {
  border-radius: 8px;
  background: #f1f1f1;
}

.frame-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.frame-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

iframe {
  overflow: hidden !important;
}
