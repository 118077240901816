.body-wrapper {
  min-height: 200px;
}

.sub-body-below-banner {
  display: flex;
}

.vertical-line {
  width: 1px;
  margin: 42px 10px 10px 10px;
  border: 1px solid #002677;
}

.i-want-to-wrapper {
  margin: 20px 10px 0px 10px;
}

.sub-header {
  font-size: 14px;
}

.body-content {
}

/* .team-details {
  height: 100px;
} */

/**
  To change width of the bottom section manipulate bottom section class
  Width equal to 69 ;-)
*/

.bottom-section {
  margin-left: 40px;
  display: flex;
  margin-top: 20px;
}

.align-center {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  background-color: aqua;
}

.points {
  width: 90%;
  margin: 10px;
  display: flex;
  border-radius: 8px;
}

.priority-content {
  width: 100%;
  margin: 5px;
  padding: 2px;
  border-right: 2px dashed purple;
}

.priority-image-container {
  width: 25%;
  margin: auto;
  text-align: center;
}

.priority-title-image {
  height: 50px;
  margin: 5px 5px 0px 0px;
}

.priority-detail {
  margin-left: 2px;
}

.priority-title-wrapper {
  justify-content: center;
}

.priority-title {
  margin: auto;
  padding-bottom: 1px;
  padding-top: 2px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  width: max-content;
  font-weight: 600;
}

.technology {
  list-style: disc !important;
  margin-left: 8px;
}

.technology > li {
  margin: 0px;
  min-height: 30px;
  /* padding: 2px; */
}

.technology > li:hover {
}

.synergy {
  list-style: disc !important;
  margin-left: 10px;
}

.people {
  list-style: disc !important;
  margin-left: 10px;
}
