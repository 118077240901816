.i-want-to-title {
  margin-top: 15px;
  color: rgb(12, 8, 77);
  font-size: large;
  font-weight: 600;
}

.i-want-to-buttons {
  width: 95% !important;
  margin: 0 !important;
  list-style: none;
}

.shortcut-links {
  width: 100%;
  text-decoration: none;
}

.shortcut-button {
  height: 45px;
  width: 100%;
  margin-bottom: 4px;
  margin-top: 2px;
  padding: 12px 16px 12px 24px;
  background: #14c6c6;
  border: none;
  border: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.shortcut-button-text {
  margin-right: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
