.description-container {
  margin: 20px;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #e5e5e5;
  box-shadow: 2px 4px #e5e5e5;
}

.page-title-container {
  padding: 10px 10px 2px 10px;
  font-weight: 1000;
}

.page-description {
  padding: 0px 10px 10px 10px;
  width: 500px;
  text-align: justify;
  color: rgb(63, 98, 129);
}
