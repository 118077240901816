.my-submenu-wrapper {
  width: 100%;
  background-color: #faf8f2;
  min-height: 20px;
  font-weight: 400;
  padding: 5px;
  position: fixed;
}

.submenu_heading {
  margin-right: 10px;
  font-size: 18px;
}

.my-submenu {
  width: 90%;
  margin: auto;
  color: black;
  display: flex;
}

.my-subMenu-content {
  display: flex;
  margin: 0px 5px;
}

.my-submenu-title {
  font-size: 16px !important;
}

.remove-link-underline {
  /* text-decoration: none !important; */
  align-items: center;
  height: 30px;
  display: flex;
  font-size: medium !important;
  color: #002677 !important;
  font-weight: 600 !important;
}

.remove-link-underline:hover {
  background-color: #002677;
  color: white !important;
}

.abyss-link-icon {
  fill: #002677 !important;
  width: 15px !important;
  height: 15px !important;
}

.remove-link-underline:hover .abyss-link-icon {
  fill: white !important;
  width: 15px !important;
  height: 15px !important;
}

/* .abyss-c-ceKPtA-fRdGHF-variant-underline {
  display: flex !important;
} */

.abyss-c-ceKPtA-fRdGHF-variant-underline:hover::before {
  width: 0% !important;
}

.subMenu-title {
  cursor: pointer;
  font-size: 18px;
}
