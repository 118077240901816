.team-details-wrapper {
  display: flex;
  width: 90%;
  margin: auto;
  padding: 40px 10px 10px 20px;
}

.image-container {
  height: 400px;
  padding-top: 0px;
  margin-right: 0px !important;
}

.team-detail-content {
  height: 400px;
  width: 80%;
  margin-left: 10px !important;
  font-size: 14px;
}

.team-title-office {
  font-size: 15px !important;
  margin-bottom: 0px !important;
}

.team-description-office {
  text-align: justify;
}

.advocacy-analytics-blade {
  min-height: 10%;
}

.customer-analysis-solutions-blade {
  min-height: 10%;
  /* margin-left: 10px; */
}

.data-resources-blade {
  min-height: 10%;
  /* margin-left: 20px; */
}

.national-accounts-blade {
  min-height: 10%;
}

.uspdra-blade {
  min-height: 10%;
}
