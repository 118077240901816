.banner {
  margin-top: 2px;
  background-color: #002677;
  /* background: url(../../../public//Heathcare_Data.png) no-repeat; */
  color: white;
  min-height: 70px;
}

.banner-content {
  width: 90%;
  margin: auto;
  padding: 8px 10px 15px 10px;
  text-align: justify;
}

.banner-title {
  font-size: x-large;
}

.banner-subcontent {
  font-size: medium;
}
