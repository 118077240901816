.flex-div {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.i-want-to-content-wrapper {
  width: 300px;
}

.banner-wrapper {
  /* margin-top: 25px; */
}

.uspdra-body-wrapper {
  margin: 5px 5px 5px 5px;
}

.uspdra-top-section {
  display: flex;
}

.uspdra-image-container {
  /* width: 80%; */
  margin: 20px 20px 0px 20px;
}

.heirarchy-image {
  width: 100%;
}

.uspdra-details-wrapper {
  display: flex;
  margin: 20px 20px 20px 20px;
}

.uspdra-detail-heading {
  text-align: center;
  background-color: #002677;
  color: white;
  padding: 2px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.uspdra-details-wrapper > div > ul > li {
  font-size: 18px;
  border-bottom: 1px dashed #002677;
  margin-right: 10px;
  margin-bottom: 35px;
}

.uspdra-image-01 {
  width: 50%;
  margin: auto;
  height: 300px;
}

.bottom-section-top {
  margin: 20px;
  display: flex;
  height: 300px;
  justify-content: space-around;
}

.dashboard-screenshots {
  width: 80%;
}
