.naaa-body-wrapper {
  margin: 8px;
  padding: 1px;
}

.headline-content-wrapper {
  display: flex;
  padding: 10px;
}
.headline-content {
  height: 300px;
  display: flex;
}

.naaa-content {
  width: 60%;
}

.ribbon {
  background-color: #002677;
  color: white;
  text-align: center;
  padding: 2px;
}

.naaa-list-container {
  padding: 5px;
}

.naaa-list-container > ol > li {
  font-size: 14px;
  padding: 5px;
  border-bottom: 1px dashed #002677;
  margin: 2px;
}

@media screen and (min-width: 1700px) {
  .naaa-list-container > ol > li {
    font-size: 18px !important;
  }

  .client-content {
    margin-top: 50px !important;
  }
}

.naaa-image {
  height: 300px;
  width: 325px;
  margin-left: 10px;
}

.client-content {
  width: 40%;
  margin: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.naaa-client-image-container {
  width: 90%;
  margin: auto;
}

.naaa-client-image {
  height: 220px;
}
