.advo-body-wrapper {
  display: flex;
  width: 100%;
  margin-top: 8px;
  padding-top: 2px;
}

.advo-image-container {
  width: 35%;
  margin-bottom: 10px;
}

.advo-description-container {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media screen and (min-width: 1800px) and (max-width: 2800px) {
  .advo-description-container-content > ul > li {
    font-size: 20px !important;
  }
  /* * {
    font-size: 20px !important;
  } */
}

@media screen and (min-width: 2801px) {
  .advo-description-container-content > ul > li {
    font-size: xx-large !important;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1950px) {
  .right-top {
    margin-top: -60px !important;
    margin-left: -25px !important;
  }
}

@media screen and (min-width: 1950px) {
  .right-top {
    margin-top: -100px !important;
    margin-left: -40px !important;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1950px) {
  .left-top {
    margin-top: -30px !important;
  }
}

@media screen and (min-width: 1950px) {
  .left-top {
    margin-top: -20px !important;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1950px) {
  .left-bottom {
    margin-top: 60px !important;
  }
}

@media screen and (min-width: 1950px) {
  .left-bottom {
    margin-top: 200px !important;
  }
}

.advo-description-container-content {
  margin: 5px;
  padding: 5px;
  /* border: 1px solid black; */
}

/* .left-bottom {
  margin-top: 25px !important;
} */

.advo-description-container-content > ul > li {
  font-size: 14px;
  border-bottom: 1px dashed #002677;
  margin-bottom: 5px;
}
